import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  AdvancedPanelWrapperComponent,
  CollapsePanelWrapperComponent,
  FormlySelectedWrapper,
  FormlyWrapperAddons,
  HeaderCollapsePanelWrapperComponent,
  PanelWrapperComponent,
  StandardExpansionPanelWrapperComponent,
  FormlyTooltipWrapper,
  FormlyPasteControlWrapper
} from './wrappers';
import {
  AutocompleteTypeComponent,
  ConfigurableFormlyInput,
  DragDropFieldType,
  DragDropHorizontalFieldType,
  CustomDatePickerType,
  FlexLayoutType,
  FormlyFieldNoWrapServiceTabs,
  FormlyFieldStepper,
  FormlyFieldTabs,
  GroupRadioType,
  MaterialTextareaAutosizeComponent,
  MatFormlyFieldFile,
  NonblockingMaterialTabsType,
  PhoneMaskDirective,
  PhoneMaskTypeComponent,
  SSNMaskDirective,
  SSNMaskTypeComponent,
  PopoverRepeatTypeComponent,
  RadioThreeChoiceType,
  RepeatTypeComponent,
  SelectableMatSelect,
  YesnoRadioType,
  MatCheckBoxListType,
  SelectedProtocolsType,
  FormlyLabelType,
  CustomLinkType,
  multiLevelDropdownType,
  multiLevelDropdown,
  CustomInputFieldType,
  CustomTextAreaComponentType,
  CustomSelectComponent
} from './types';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskModule } from 'ngx-mask';
import { MatRadioModule } from '@angular/material/radio';
import { FormlyMatSliderModule } from '@ngx-formly/material/slider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import {FileValueAccessor} from "./helpers";
import {FormlySelectModule} from "@ngx-formly/core/select";
import {FormlyFieldServiceTabs} from "./types/material-tabs-service-type";
import {ReactiveComponentModule} from "@ngrx/component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import { ConfirmDialogControlComponent } from './types/confirm-dialog-control';

const COMPONENTS = [
  PanelWrapperComponent,
  CollapsePanelWrapperComponent, FileValueAccessor,
  FlexLayoutType, MatFormlyFieldFile,FormlyFieldServiceTabs,FormlyFieldNoWrapServiceTabs,
  StandardExpansionPanelWrapperComponent,NonblockingMaterialTabsType,FormlyFieldStepper,YesnoRadioType, SelectedProtocolsType, CustomDatePickerType, ConfigurableFormlyInput,
  FormlySelectedWrapper,GroupRadioType,
   HeaderCollapsePanelWrapperComponent,PopoverRepeatTypeComponent, MatCheckBoxListType, RadioThreeChoiceType, CustomInputFieldType, CustomSelectComponent, RepeatTypeComponent,FormlyFieldTabs,DragDropFieldType, PhoneMaskTypeComponent, PhoneMaskDirective,SSNMaskTypeComponent, SSNMaskDirective, FormlyWrapperAddons,FormlyTooltipWrapper, FormlyPasteControlWrapper
   , MaterialTextareaAutosizeComponent, CustomTextAreaComponentType, DragDropHorizontalFieldType, AdvancedPanelWrapperComponent, SelectableMatSelect, AutocompleteTypeComponent, FormlyLabelType, ConfirmDialogControlComponent, CustomLinkType, multiLevelDropdownType];
const BASE = [FormsModule, ReactiveFormsModule, FlexLayoutModule, NgxMaskModule, TranslateModule];
const MATERIAL = [MatCardModule, MatStepperModule, MatTabsModule, MatButtonModule, MatCheckboxModule, MatExpansionModule, MatInputModule, MatFormFieldModule,DragDropModule,
  MatNativeDateModule,
  FormlyMatDatepickerModule,
  MatInputModule,
  MatIconModule,
  MatAutocompleteModule,
];
const FORMLY = [
  FormlyModule,
  FormlyMaterialModule,
  FormlyMatToggleModule,
  FormlyMatDatepickerModule,
  MatNativeDateModule,
  FormlyMatSliderModule,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    ...BASE,
    ...MATERIAL,
    ...FORMLY,
    MatSelectModule,
    MatRadioModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatDialogModule,
    FormlySelectModule,
    ReactiveComponentModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaskModule,
  ],
  exports: [...BASE, ...COMPONENTS, ...FORMLY],
})
export class DynamicFormModule {}
