import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironmentConfig } from '../environment';
import { throwError } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { EmployeeHrFeed, StoreManager } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StoreManagerService {
  searchManager(term: string, Id: string) {
      let searchurl =`${this.environment.apiConfig.accountsUri}/api/storemanager/search?searchText=${term}&accountId=${Id}`;
      return this.http
      .get<StoreManager[]>(searchurl)
      .pipe(retry(1), catchError(this.httpError.bind(this)),shareReplay(1));
  }
  searchEmployee(term: string, Id: string) {
    let searchurl =`${this.environment.apiConfig.accountsUri}/api/employee/search?searchText=${term}&accountId=${Id}`;
    return this.http
    .get<EmployeeHrFeed[]>(searchurl)
    .pipe(retry(1), catchError(this.httpError.bind(this)),shareReplay(1));
}

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      msg = error.error.message;
    } else {
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(msg);
  }

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private environment: IEnvironmentConfig
  ) {}
}