import { Case, CaseAssignment, Dashboard, DashboardScope, DbCase, UnAssignedCases } from '@nfr/common';
import { Observable } from 'rxjs';
import {
  getAccounts,
  isLoading,
  selectDashboard,
  getCaseInfoById,
  getUnAssignedCasesTableData,
  getUnAssignedFollowUpsTableData,
  getAccountsByFeatures,
  getDraftCasesTableData,
  getCompletedCasesTableData,
} from '../selectors/dashboard.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadCompletedCases, loadDraftCases} from '../actions/dashboard.actions';
import { DashboardState } from '../reducers/dashboard.reducer';
import { assignCase, assignFollowUp, changeDashboardScope } from '../actions/dashboard-api.actions';
import { Account, AccountMinimalView } from '@accounting/common';
import { Guid, NgrxRoute } from '@autobot/core';

@Injectable({ providedIn: 'root' })
export class DashboardFacadeImpl implements DashboardFacade {
  loading$: Observable<boolean> = this.store.select(isLoading);
  dashboard$: Observable<Dashboard> = this.store.select(selectDashboard);
  accounts$: Observable<any[]> = this.store.select(getAccounts);

  tableDraftCasesData$: Observable<any[]> = this.store.select(getDraftCasesTableData);
  tableCompletedCasesData$: Observable<any[]> = this.store.select(getCompletedCasesTableData);
  tableUnAssignedCasesData$: Observable<any[]> = this.store.select(getUnAssignedCasesTableData);
  tableUnAssignedFollowUpsData$: Observable<any[]> = this.store.select(getUnAssignedFollowUpsTableData);
  tableAssignedFollowUpData$: Observable<any[]> = this.store.select(getUnAssignedFollowUpsTableData);

  getAccountsByFeatures(filterKeys: string[]): Observable<Account[]> {
    return this.store.select(getAccountsByFeatures(filterKeys));
  }

  getCaseViewDetails(caseId: Guid): Observable<UnAssignedCases> {
    return this.store.select(getCaseInfoById(caseId));
  }


  constructor(private store: Store<DashboardState>) { }

  assignCase(data: CaseAssignment): void {
    this.store.dispatch(assignCase({ data: data }));
  }

  assignFollowUp(data: CaseAssignment): void {
    this.store.dispatch(assignFollowUp({ data: data }));
  }

  getDraftCases(): void {
    this.store.dispatch(loadDraftCases());
  }

  getCompletedCases(): void {
    this.store.dispatch(loadCompletedCases());
  }

  changeScope(scp: DashboardScope): void {
    this.store.dispatch(changeDashboardScope({ scope: scp }));
  }
}

export abstract class DashboardFacade {
  abstract dashboard$: Observable<Dashboard>;
  abstract loading$: Observable<boolean>;
  abstract getDraftCases(): void;
  abstract getCompletedCases(): void;
  abstract changeScope(scp: DashboardScope): void;
  abstract accounts$: Observable<AccountMinimalView[]>;
  abstract tableDraftCasesData$: Observable<any[]>;
  abstract tableCompletedCasesData$: Observable<any[]>;
  abstract tableUnAssignedCasesData$: Observable<any[]>;
  abstract tableUnAssignedFollowUpsData$: Observable<any[]>;
  abstract tableAssignedFollowUpData$: Observable<any[]>;
  abstract getCaseViewDetails(caseId: Guid): Observable<UnAssignedCases>;
  abstract getAccountsByFeatures(filterKeys: string[]): Observable<Account[]>;
  abstract assignCase(data: CaseAssignment): void;
  abstract assignFollowUp(data: CaseAssignment): void;
}
