import { selectUserProfile } from '@autobot/authentication';
import { flattenObject, selectCurrentRoute, selectUrl } from '@autobot/core';
import { Field } from '@forms/common';
import {
  CaseMetaData,
  CaseType,
  RightSideTabs,
  SelectedDisposition,
  TelemedicineConfigs,
} from '@nfr/common';
import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { selectCustomFormState } from './custom-form.selector';
import { selectSessionFeatureState } from './session.selectors';
import { getSelectedAccountDetailView } from '@accounting/data';
export const selectCurrentState = createSelector(
  selectSessionFeatureState,
  (state) => state
);
export const selectCaseState = createSelector(
  selectSessionFeatureState,
  (state) => state.case
);
export const selectCaseMetaData = createSelector(
  selectSessionFeatureState,
  (state) => state?.case?.metaData as CaseMetaData
);

export const selectIsTelemedicineDispositionAvailable = createSelector(
  selectCaseMetaData,
  (data) => {
    return typeof data?.isTelemedicineDispositionAvailable === 'boolean'
      ? data?.isTelemedicineDispositionAvailable
      : data?.isTelemedicineDispositionAvailable === 'true' || false;
  }
);

export const selectSelectedDisposition = createSelector(
  selectSessionFeatureState,
  (state) => state?.case?.metaData?.selectedDisposition
);

export const getCaseId = createSelector(selectCaseState, (state) => state.id);
export const getCaseStatus = createSelector(selectCaseState, (state) =>
  state?.status?.toUpperCase()
);
export const selectModel = createSelector(
  selectCaseState,
  (state) => state.model
);

export const selectAssembly = createSelector(
  selectCaseState,
  (state) => state.assembly
);

export const selectFNOLCaseFormList = createSelector(
  selectCaseState,
  (state) => state.validFNOLCaseFormList
);

export const selectDeathCaseFormList = createSelector(
  selectCaseState,
  (state) => state.validDeathCaseFormList
);

export const selectNurseTriageCaseFormList = createSelector(
  selectCaseState,
  (state) => state.validNurseTriageCaseFormList
);
export const selectCaseValidFormList=createSelector(
  selectFNOLCaseFormList,
  selectDeathCaseFormList,
  selectNurseTriageCaseFormList,
  (fnol,death,nursetriage)=>
  {
return{
  fnol,death,nursetriage
}
  }

)
export const selectSaved = createSelector(
  selectCaseState,
  (state) => state.saved
);
export const selectSaving = createSelector(
  selectCaseState,
  (state) => state.saving
);
export const selectLoading = createSelector(
  selectCaseState,
  (state) => state.loading
);
export const isCaseLoaded = createSelector(
  selectCaseState,
  (state) => state.loaded
);
export const selectFormAssembly = createSelector(
  selectCaseState,
  selectAssembly,
  isCaseLoaded,
  (state, assembly, loaded) => {
    if (!loaded) return [];

    const formTemplates = assembly?.forms?.filter((v) => v.templateId !== '');
    return formTemplates;
  }
);
export const selectFollowUpTemplates = createSelector(
  selectAssembly,
  isCaseLoaded,
  (assembly, loaded) => {
    if (!loaded) return [];

    const formTemplates = assembly?.forms?.find((v) => v.key == 'followup')
                              .menuItems[0].childTemplates;
    return formTemplates;
  }
);

export const getFields = createSelector(
  selectCaseState,
  selectAssembly,
  isCaseLoaded,
  selectFormAssembly,
  (state, assembly, loaded, formAssembly) => {
    if (!loaded) return [];
    const templates = formAssembly.map((e) => e.template);

    const fn = _.spread(_.union);
    const fields = templates.map((t) => t.fields);

    const result: any[] = fn(fields);

    return result;
  }
);

export const getFirstCall = createSelector(
  selectCaseState,
  (state) => state.calls[0]
);

export const getWizardSteps = createSelector(
  selectCaseState,
  selectAssembly,
  isCaseLoaded,
  (state, assembly, loaded) => {
    if (!loaded) return [];
    const templates = assembly.forms.map((e) => e.template);
    const fn = _.spread(_.union);
    const fields = templates.map((t) => t.fields);

    const result: any[] = fn(fields);

    return result;
  }
);

export const selectCaseFormKeys = createSelector(
  selectCaseState,
  getFields,
  (state, fields) => {
    if (!fields) return [];
    const keys: string[] = fields.map((f) => f.key?.toString());
    return keys ?? [];
  }
);

export const getFlatCaseModel = createSelector(
  selectCaseState,
  getFields,
  (state, fields) => {
    if (!state.assembly) return [];
    const model = state.model;
    const obj = flattenObject(model);

    const fieldBased = _.forEach(fields).map((e: Field) => {
      const key = e.key;
      const row = Object.entries(obj).filter((f) => f[0] == key)[0] ?? '';
      const baseKey = e.key.split('.')[0];
      const value = row[1] ?? '';
      const field = e;
      return {
        key,
        value,
        field,
        baseKey,
      };
    });

    return fieldBased;
  }
);

export const getFlatEmployeeData = createSelector(
  selectCaseState,
  getFlatCaseModel,
  (state, fields) => {
    return fields.filter((f) => f.key.toLowerCase().indexOf('employee') != -1);
  }
);
export const getCasePutObject = createSelector(
  selectCaseState,
  selectUserProfile,
  selectCustomFormState,
  selectCaseMetaData,
  (state, profile, customPageState, caseMetaData: CaseMetaData) => {
    let _casemodel = state.model ?? {};
    let _disableReportOnlyAndSoughtMedical = false;
    if (state.status === 'Completed') {
      if (
        state?.model?.planofcare?.disposition?.dispositionRecommended != null &&
        state?.model?.planofcare?.disposition?.dispositionRecommended !=
          undefined &&
        [
          'SELF-CARE',
          'TELEMEDICINE',
          'OFFICE VISIT',
          'URGENT CARE',
          'EMERGENCY ROOM',
          '911',
        ].includes(
          state?.model?.planofcare?.disposition?.dispositionRecommended?.toUpperCase()
        )
      ) {
        _disableReportOnlyAndSoughtMedical = true;
      }
    }
    let _metaData = caseMetaData ?? _casemodel.metaData;

    _metaData = {
      ..._metaData,
      disableReportOnlyAndSoughtMedical: _disableReportOnlyAndSoughtMedical,
      validCaseFormList: state.validCaseFormList,
      completing: state.completing,
    };

    const _casedata = {
      ..._casemodel,
      metaData: _metaData,
    };
    return {
      id: state.id,
      claimant: {
        firstName: state.claimantFirstName,
        lastName: state.claimantLastName,
        employeeKey: '',
      },
      status: state.status,
      notes: state.notes ?? [],
      data: _casedata,
      protocols: state.protocols, //It is selected protocol;To be merged in single data modal later
      props: state.props ?? {},
      caseType: _metaData?.caseType,
      updatedBy: profile?.email,
    };
  }
);

export const getNotes = createSelector(selectCaseState, (state) => {
  return _.orderBy(state.notes, 'created', ['desc']);
});

export const getProtocols = createSelector(selectCaseState, (state) => {
  return _.orderBy(state?.protocols, 'created', ['desc']);
});
export const getNotePutObject = createSelector(
  getNotes,
  selectUserProfile,
  (noteState, profile) => {
    return {
      notes: noteState ?? [],
      updatedBy: profile.email,
    };
  }
);

export function findKey(model, key) {
  model.find((o) => o.key === key);
}
export const getCurrentRouteIForm = createSelector(
  selectSessionFeatureState,
  isCaseLoaded,
  (sessionState, loaded) => {
    if (!loaded) return null;
    return sessionState?.editor?.iForm;
  }
);
export const getEmergencyButtonAttribute = createSelector(
  selectCaseState,
  isCaseLoaded,
  (state, loaded) => {
    if (!loaded) return {};
    return state?.emergencyCaseButtonAttribute;
  }
);
export const getIsEmergencyCase = createSelector(
  selectCaseMetaData,
  isCaseLoaded,
  (state, loaded) => {
    if (!loaded) return false;
    return state?.caseType == CaseType.Emergency;
  }
);
export const getIsProtocolnstruction = createSelector(
  selectCaseState,
  isCaseLoaded,
  (state, loaded) => {
    if (!loaded) return false;
    return state?.protocols?.some(item=>
      _.get(item, 'props.hasSpecialInstructions')
    );
  }
);

export const getSelectedRightSideTab = createSelector(
  getIsEmergencyCase,
  isCaseLoaded,
  getIsProtocolnstruction,
  selectUrl,
  (isEmergency, loaded, hasProtocolInstruction, currentUrl) => {
    if (!loaded) return RightSideTabs.Info;
    if (!isEmergency && loaded) {
      const ps = currentUrl.includes("providersearch") || currentUrl.includes("followup");
      return ((hasProtocolInstruction || ps) ? RightSideTabs.SpecialInstruction : RightSideTabs.Info);
    }

    return isEmergency ? RightSideTabs.Emergency : RightSideTabs.Info;
  }
);
export const getEmergencyButtonDisabledStatus = createSelector(
  selectCaseState,
  getCurrentRouteIForm,
  isCaseLoaded,
  (state, caseRouteFormDetails, loaded) => {
    if (!loaded) return false;
    const currentRouteKey = caseRouteFormDetails?.key?.toUpperCase();
    const isDisabledRoute =
      currentRouteKey &&
      (currentRouteKey == 'SUPPLEMENTAL' ||
        currentRouteKey == 'CUSTOMQUESTIONS' ||
        currentRouteKey == 'COMPLETION');

    var caseStatus = state.status.toUpperCase();
    return isDisabledRoute || caseStatus !== 'DRAFT';
  }
);
export const getIsViewOnlyMode = createSelector(
  selectCaseState,
  isCaseLoaded,
  (state, loaded) => {
    if (!loaded) return false;
    return state?.status?.toUpperCase() == 'COMPLETED';
  }
);
export const selectTelemedicineConfig = createSelector(
  getSelectedAccountDetailView,
  (state) => {
    const _tc = _.get(
      _.find(state.configs, { name: 'TelemedicineConfiguration' }),
      'value',
      ''
    );
    return _tc as TelemedicineConfigs;
  }
);
