import { createReducer, on } from '@ngrx/store';

import * as moment_ from 'moment';
import { Case, Dashboard, DashboardScope, DbCase } from '@nfr/common';
import { DashboardActions, DashboardApiActions } from '../actions';
import { Account, AccountMinimalView } from '@accounting/common';

export const dashboardFeatureKey = 'dashboard';
const moment = moment_;

export interface DashboardState {
  loaded: boolean;
  loading: boolean;
  assignCaseSuccess: boolean;
  data: any[];
  accounts: any[];
  dashboard: Dashboard;
  tableScope: DashboardScope;
  error?: any;
  draftCases: any;
  completedCases: any;
  unAssignedCases: any;
  unAssignedFollowUps: any;
  assignedFollowUps: any;
}

export const initialState: DashboardState = {
  loaded: false,
  loading: false,
  data: [],
  assignCaseSuccess: false,
  dashboard: {
    filterDates: {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().toDate(),
    },
    series: [],
  },
  draftCases: [],
  completedCases: [],
  unAssignedCases: [],
  unAssignedFollowUps: [],
  assignedFollowUps: [],
  accounts: [],
  tableScope: DashboardScope.month,
};

export const reducer = createReducer(
  initialState,

  on(DashboardActions.loadAccountsTree, (state) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(DashboardApiActions.loadAccountsTreeSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      accounts: action.accounts,
    };
  }),
  on(DashboardApiActions.loadAccountsTreeFailure, (state, action) => {
    return {
      ...state,
      selectedLocationId: undefined,
      loading: false,
      loaded: false,
      error: action.error,
    };
  }),
  on(DashboardActions.loadDraftCases, (state) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(DashboardApiActions.loadDraftCasesSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      draftCases: action.draftCases,
    };
  }),
  on(DashboardApiActions.loadDraftCasesFailure, (state, action) => {
    return {
      ...state,
      selectedLocationId: undefined,
      loading: false,
      loaded: false,
      error: action.error,
    };
  }),
  on(DashboardActions.loadCompletedCases, (state) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(DashboardApiActions.loadCompletedCasesSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      completedCases: action.completedCases,
    };
  }),
  on(DashboardApiActions.loadCompletedCasesFailure, (state, action) => {
    return {
      ...state,
      selectedLocationId: undefined,
      loading: false,
      loaded: false,
      error: action.error,
    };
  }),
  on(DashboardActions.loadUnAssignedCases, (state) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(DashboardApiActions.loadUnAssignedCasesSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      unAssignedCases: action.unAssignedCases
    };
  }),
  on(DashboardApiActions.loadUnAssignedCasesFailure, (state, action) => {
    return {
      ...state,
      selectedLocationId: undefined,
      loading: false,
      loaded: false,
      error: action.error,
    };
  }),
  on(DashboardActions.loadUnAssignedFollowUps, (state) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(DashboardApiActions.loadUnAssignedFollowUpSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      unAssignedFollowUps: action.unAssignedFollowUps,
    };
  }),
  on(DashboardApiActions.loadUnAssignedFollowUpFailure, (state, action) => {
    return {
      ...state,
      selectedLocationId: undefined,
      loading: false,
      loaded: false,
      error: action.error,
    };
  }),
  on(DashboardActions.loadAssignedFollowUps, (state) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(DashboardApiActions.loadAssignedFollowUpSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      assignedFollowUps: action.assignedFollowUps,
    };
  }),
  on(DashboardApiActions.loadAssignedFollowUpFailure, (state, action) => {
    return {
      ...state,
      selectedLocationId: undefined,
      loading: false,
      loaded: false,
      error: action.error,
    };
  }),
  on(DashboardApiActions.changeDashboardScope, (state, action) => {
    return { ...state, tableScope: action.scope };
  }),
  on(DashboardApiActions.assignCase, (state) => {
    return { ...state };
  }),
  on(DashboardApiActions.assignCaseSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      assignCaseSuccess: true
    };
  }),
  on(DashboardApiActions.assignCaseFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      assignCaseSuccess: false,
      error: action.error,
    };
  }),
);
