import { createAction, props } from '@ngrx/store';
import {
  AssignedCases,
  AssignedFollowUps,
  Case,
  CaseAssignment,
  DashboardScope,
  DbCase,
  UnAssignedCases,
  UnAssignedFollowUps,
} from '@nfr/common';
import { AccountMinimalView } from '@accounting/common';
import { NgrxRoute } from '@autobot/core';

export enum DashboardApiActionsTypes {
  LOAD_ACCOUNTS_TREE_SUCCESS = '[Dashboard/API] Load Accounts Tree success',
  LOAD_ACCOUNTS_TREE_FAILURE = '[Dashboard/API] Load Accounts Tree failure',

  LOAD_DRAFT_CASES_SUCCESS = '[Dashboard/API] Load Draft Cases success',
  LOAD_DRAFT_CASES_FAILURE = '[Dashboard/API] Load Draft Cases failure',

  LOAD_COMPLETED_CASES_SUCCESS = '[Dashboard/API] Load Completed Cases success',
  LOAD_COMPLETED_CASES_FAILURE = '[Dashboard/API] Load Completed Cases failure',

  CHANGE_DASHBOARD_SCOPE = '[Dashboard/Page] Change Dashboard Scope',

  LOAD_UNASSIGNED_CASES_SUCCESS = '[Pending Assignments Table] Load Pending UnAssigned Cased Success',
  LOAD_UNASSIGNED_CASES_FAILURE = '[Pending Assignments Table] Load Pending UnAssigned Cased Failure',

  LOAD_UNASSIGNED_FOLLOWUP_SUCCESS = '[Pending Follow Up Assignments] Load Pending Assigned FollowUp Success',
  LOAD_UNASSIGNED_FOLLOWUP_FAILURE = '[Pending Follow Up Assignments] Load Pending Assigned FollowUp Failure',

  LOAD_ASSIGNED_FOLLOWUP_SUCCESS = '[My Cases] Load My Assigned FollowUp Success',
  LOAD_ASSIGNED_FOLLOWUP_FAILURE = '[My Cases] Load My Assigned FollowUp Failure',

  ASSIGN_CASE = '[Case/API] Assign Case to logged in User and Navigate',
  ASSIGN_CASE_SUCCESS = '[Case/API] Assign Case to logged in User Success',
  ASSIGN_CASE_FAILURE = '[Case/API] Assign Case to logged in User Failure',

  ASSIGN_FOLLOWUP = '[Case/API] Assign Follow Up to Logged In User',
  ASSIGN_FOLLOWUP_SUCCESS = '[Case/API] Follow Up to Logged In User Success',
  ASSIGN_FOLLOWUP_FAILURE = '[Case/API] Follow Up to Logged In User Failure',
}

export const loadAccountsTreeSuccess = createAction(
  DashboardApiActionsTypes.LOAD_ACCOUNTS_TREE_SUCCESS,
  props<{ accounts: AccountMinimalView[] }>()
);

export const loadAccountsTreeFailure = createAction(
  DashboardApiActionsTypes.LOAD_ACCOUNTS_TREE_FAILURE,
  props<{ error: any }>()
);

export const loadDraftCasesSuccess = createAction(
  DashboardApiActionsTypes.LOAD_DRAFT_CASES_SUCCESS,
  props<{ draftCases: AssignedCases[] }>()
);

export const loadDraftCasesFailure = createAction(
  DashboardApiActionsTypes.LOAD_DRAFT_CASES_FAILURE,
  props<{ error: any }>()
);

export const loadCompletedCasesSuccess = createAction(
  DashboardApiActionsTypes.LOAD_COMPLETED_CASES_SUCCESS,
  props<{ completedCases: AssignedCases[] }>()
);

export const loadCompletedCasesFailure = createAction(
  DashboardApiActionsTypes.LOAD_COMPLETED_CASES_FAILURE,
  props<{ error: any }>()
);

export const loadUnAssignedCasesSuccess = createAction(
  DashboardApiActionsTypes.LOAD_UNASSIGNED_CASES_SUCCESS,
  props<{ unAssignedCases: UnAssignedCases[] }>()
);

export const loadUnAssignedCasesFailure = createAction(
  DashboardApiActionsTypes.LOAD_UNASSIGNED_CASES_FAILURE,
  props<{ error: any }>()
);

export const loadUnAssignedFollowUpSuccess = createAction(
  DashboardApiActionsTypes.LOAD_UNASSIGNED_FOLLOWUP_SUCCESS,
  props<{ unAssignedFollowUps: UnAssignedFollowUps[] }>()
);

export const loadUnAssignedFollowUpFailure = createAction(
  DashboardApiActionsTypes.LOAD_UNASSIGNED_FOLLOWUP_FAILURE,
  props<{ error: any }>()
);

export const loadAssignedFollowUpSuccess = createAction(
  DashboardApiActionsTypes.LOAD_ASSIGNED_FOLLOWUP_SUCCESS,
  props<{ assignedFollowUps: AssignedFollowUps[] }>()
);

export const loadAssignedFollowUpFailure = createAction(
  DashboardApiActionsTypes.LOAD_ASSIGNED_FOLLOWUP_FAILURE,
  props<{ error: any }>()
);

export const changeDashboardScope = createAction(
  DashboardApiActionsTypes.CHANGE_DASHBOARD_SCOPE,
  props<{ scope: DashboardScope }>()
);

export const assignCase = createAction(
  DashboardApiActionsTypes.ASSIGN_CASE,
  props<{ data: CaseAssignment }>()
);

export const assignCaseSuccess = createAction(
  DashboardApiActionsTypes.ASSIGN_CASE_SUCCESS
);

export const assignCaseFailure = createAction(
  DashboardApiActionsTypes.ASSIGN_CASE_FAILURE,
  props<{ error: any }>()
);

export const assignFollowUp = createAction(
  DashboardApiActionsTypes.ASSIGN_FOLLOWUP,
  props<{ data: CaseAssignment }>()
);

export const assignFollowUpSuccess = createAction(
  DashboardApiActionsTypes.ASSIGN_FOLLOWUP_SUCCESS
);

export const assignFollowUpFailure = createAction(
  DashboardApiActionsTypes.ASSIGN_FOLLOWUP_FAILURE,
  props<{ error: any }>()
);
