import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { EmployeeHrFeed, IEnvironmentConfig } from '@autobot/core';
import { StoreManagerService, StoreManager } from '@autobot/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Observable, finalize, map, of, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.scss'],
})
export class EmployeeSearchComponent implements OnInit {
  constructor(
    private service: StoreManagerService,
    @Inject('ENVIRONMENT') private environment: IEnvironmentConfig
  ) {}
  @ViewChild(MatInput) formFieldControl: MatInput;

  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;
  @Output() employeeSearchValue = new EventEmitter<any>();
  @Output() clearSearchValue = new EventEmitter<any>();
  @Input() accountId: string;
  @Input() employeeHRFeedEnable: string;
  @Input() disabledField: any;
  filter$: Observable<EmployeeHrFeed[]>;
  isLoading = false;
  errorMsg!: string;
  minLengthTerm = 3;
  employeeControl = new FormControl();
  ngOnInit(): void {
    if (this.disabledField || this.employeeHRFeedEnable !='true') {
      this.employeeControl.disable();
    } else {
      this.employeeControl.enable();
    }
  }

  onKeyup(event: KeyboardEvent) {
    // Ignore arrow keys and enter key
    if (event.key !== 'ArrowDown' && event.key !== 'ArrowUp' && event.key !== 'Enter') {
      this.loadRecords(event);
    }
  }

  loadRecords(event: any) {
    const searchItem = event.target.value;
    if (searchItem.length >= 3 && this.accountId) {
      this.filter$ = this.service.searchEmployee(searchItem, this.accountId);
    } else {
      this.filter$ = of(null);
    }
  }

  onSelectionChange(event) {
    if (event.label) {
      this.employeeSearchValue.emit(event);
    }
  }

  clear() {
    this.employeeControl.setValue('');
    this.filter$ = of(null);
    this.clearSearchValue.emit();
  }
}