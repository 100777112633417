import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FieldWrapper } from '@ngx-formly/core';
import { ConfirmDialogControlComponent } from '../types/confirm-dialog-control';
import { selectCaseFieldValue } from '../selectors/core-model.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'formly-wrapper-paste-control',
  template: `
  <ng-container #fieldComponent></ng-container>
  <span *ngIf="props.pasteFromField?.text" class="button-container">
      <button
      mat-raised-button
      color="primary"
      class="btn-sm"
      (click)="onPasteButtonClick()"
      [attr.title]="buttonTooltip"
      [disabled]="copiedSourceFieldText.length === 0 || this.form.disabled"
      >{{ 'Paste ' + buttonText }}
      </button>
  </span>
        `,
  styles: [`
  ::ng-deep formly-wrapper-paste-control{
    position: relative
  }
   .button-container {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(14%, -50%);
    }
  `]
})
export class FormlyPasteControlWrapper extends FieldWrapper {
  copiedSourceFieldText: string;
  buttonText: string;
  buttonTooltip: string;
  lookupSourcePath: string;

  constructor(private store: Store, private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.lookupSourcePath = this.props.pasteFromField?.text || '';

    // Subscribe with cleanup using untilDestroyed
    this.store.select(selectCaseFieldValue(this.lookupSourcePath))
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.copiedSourceFieldText = data.fieldText;
      });

    this.updateButtonProps();
  }

  onPasteButtonClick() {
    const currentFieldValue = this.formControl.value ? this.formControl.value.trim() : '';
    if (currentFieldValue) {
      this.showPopup();
    } else {
      this.formControl.setValue(this.copiedSourceFieldText);
    }
  }

  showPopup() {
    const displayMessage = "<b>" + this.props.label + "</b> field already contains information. By clicking OK you will overwrite the existing information.";
    const data = {
      title: 'Alert',
      message: displayMessage,
      acceptButtonText: 'Ok',
      showXButton: true
    };

    const dialogRef = this.dialog.open(ConfirmDialogControlComponent, { width: '450px', data, disableClose: true });
    dialogRef.afterClosed().subscribe((userConfirmation) => {
      if (userConfirmation) {
        this.formControl.setValue(this.copiedSourceFieldText);
      }
    });
  }

  private extractButtonProps(props: any): { buttonText: string; buttonTooltip: string } {
    let buttonText = '';
    let buttonTooltip = '';
  
    if (props && props.length > 0) {
      const splitText = props.split('>');
      buttonText = splitText[0] || '';
  
      if (splitText.length > 1) {
        buttonTooltip = `${splitText[1]}\n${this.copiedSourceFieldText}`;
      }
    }
  
    return { buttonText, buttonTooltip };
  }
  
  private updateButtonProps() {
    const { buttonText, buttonTooltip } = this.extractButtonProps(this.props.pasteButtonProps?.text || '');
    this.buttonText = buttonText;
    this.buttonTooltip = buttonTooltip;
  }
}
