/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormlyModule} from "@ngx-formly/core";
import { FormlyLookuphintAutocompleteComponent } from "./formly-lookuphint-autocomplete.component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AutocompleteSelectionModule } from '@autobot/shared';


@NgModule({
  declarations: [FormlyLookuphintAutocompleteComponent],
  imports: [
    CommonModule, FormlyModule.forChild({
      types: [{
        name: 'lookuphint',
        component: FormlyLookuphintAutocompleteComponent,
        // wrappers: ['form-field'],
      }],
    }), ScrollingModule, MatAutocompleteModule, ReactiveFormsModule, MatInputModule,MatIconModule,MatButtonModule,AutocompleteSelectionModule
  ],
  exports:[
    FormlyLookuphintAutocompleteComponent
  ]
})
export class FormlyLookupHintAutocompleteModule { }
