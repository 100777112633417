import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-select',
  template: `
    <div
      class="custom-select-wrapper"
      [ngClass]="{
        'required-field': props.required,
        'disabled-field': formControl.disabled,
        'valid-field': formControl.valid
      }"
    >
      <mat-label>
        {{ to.label }}
      </mat-label>

      <mat-form-field appearance="fill" class="dropdown-field">
        <mat-select
          [formControl]="formControl"
          [id]="field.id"
          [placeholder]="to.placeholder"
          [required]="to.required"
        >
          <mat-option
            *ngFor="let option of normalizedOptions"
            [value]="option.value"
          >
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <small
        *ngIf="formControl.invalid && formControl.touched"
        class="error-message"
      >
        <formly-validation-message *ngIf="formControl.value" [field]="field"></formly-validation-message>
      </small>
    </div>
  `,
  styles: [
    `
      .custom-select-wrapper {
        display: flex;
        flex-direction: column;
      }

      .custom-select-wrapper mat-label {
        font-size: 12px;
        color: #212529;
      }

      .custom-select-wrapper.required-field > mat-label::after {
        content: ' *';
        font-size: 12px;
        color: #ff0000;
      }

      .custom-select-wrapper.disabled-field > mat-label::after,
      .custom-select-wrapper.valid-field > mat-label::after {
        color: #212529;
      }

      .custom-select-wrapper
        ::ng-deep
        .mat-form-field-appearance-fill
        .mat-select-arrow-wrapper {
        transform: none;
      }

      .custom-select-wrapper .dropdown-field {
        width: 100%;
        max-width: 580px;
      }

      .custom-select-wrapper
        .dropdown-field
        ::ng-deep
        .mat-form-field-wrapper
        .mat-form-field-flex {
        border: 2px solid #999999;
        background-color: #ffffff;
        font-size: 14px;
        transition: border-color 0.3s;
        padding-top: 0;
        border-radius: 6px;
      }

      .custom-select-wrapper
        .dropdown-field
        ::ng-deep
        .mat-form-field-wrapper
        .mat-form-field-flex:hover {
        border-color: #7ea6e0;
      }

      .custom-select-wrapper
        .dropdown-field
        ::ng-deep
        .mat-form-field-wrapper
        .mat-form-field-flex:focus {
        border-color: #7ea6e0;
      }

      .custom-select-wrapper
        .dropdown-field
        ::ng-deep
        > .mat-form-field-wrapper
        > .mat-form-field-underline {
        display: none;
      }

      .custom-select-wrapper
        .dropdown-field
        ::ng-deep
        > .mat-form-field-wrapper
        > .mat-form-field-underline::before {
        display: none;
      }

      .custom-select-wrapper
        .dropdown-field.ng-touched.ng-invalid
        ::ng-deep
        .mat-form-field-wrapper
        .mat-form-field-flex {
        border-color: #ff0000;
      }

      .custom-select-wrapper.required-field
        .dropdown-field.ng-invalid
        ::ng-deep
        .mat-form-field-wrapper
        .mat-form-field-flex {
        background-color: #fff2cc;
      }

      .custom-select-wrapper.disabled-field
        .dropdown-field
        ::ng-deep
        > .mat-form-field-wrapper
        > .mat-form-field-flex {
        background-color: #e6e6e6;
        border-color: #999999;
      }

      .custom-select-wrapper mat-option {
        white-space: normal;
        word-wrap: break-word;
      }

      .custom-select-wrapper .error-message {
        color: #ff0000;
        font-size: 10px;
      }
    `,
  ],
})
export class CustomSelectComponent extends FieldType<FieldTypeConfig> {
  get normalizedOptions() {
    const options: any[] = this.props.options as any[]; 
    return options;
  }
}
