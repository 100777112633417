import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment-timezone';
import { Observable, map, of } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FieldValidatorFn } from '@ngx-formly/core/lib/models';
import { ConfirmDialogControlComponent } from './types/confirm-dialog-control';
import { Injectable } from '@angular/core';
import { CaseCoreApiService } from '@autobot/core';

export function minLengthValidationMessage(
  error: any,
  field: FormlyFieldConfig
) {
  return `Should have at least ${field.props.minLength} characters`;
}

export function maxLengthValidationMessage(
  error: any,
  field: FormlyFieldConfig
) {
  return `This value should be less than ${field.props.maxLength} characters`;
}

export function minValidationMessage(error: any, field: FormlyFieldConfig) {
  return `This value should be more than/equal to ${field.props.min}`;
}

export function maxValidationMessage(error: any, field: FormlyFieldConfig) {
  return `This value should be less than/equal to ${field.props.max}`;
}

export function dateFutureValidationMessage(
  error: any,
  field: FormlyFieldConfig
) {
  return `${error} is future time. The future time is not allowed for this field.`;
}

export function dateofinjuryValidator(
  control: AbstractControl
): ValidationErrors {
  if (control.value == undefined || control.value == null) {
    return null;
  }

  const today = new Date();
  const minimumDate = new Date(
    today.getFullYear() - 10,
    today.getMonth(),
    today.getDate()
  );

  const minDate = minimumDate.toISOString().split('T')[0];
  if (control.value < minDate) {
    return {
      'dateofinjury-validation': '',
    };
  }
}
export function dateofinjuryValidationMessage(
  error: any,
  field: FormlyFieldConfig
) {
  return `Please input a date of injury not exceeding 10 years ago`;
}

export function dateFormatValidationMessage(
  error: any,
  field: FormlyFieldConfig
) {
  return `Entered date is invalid. Date should be in mm/dd/yyyy format`;
}
export function dateFormatValidator(
  control: AbstractControl
): ValidationErrors {
  if (!control.value && control.dirty) {
    return { dateValidator: true };
  }
  if (
    control.value === undefined ||
    control.value === null ||
    control.value === ''
  ) {
    return null;
  }
  var date = moment(control.value);
  const year = date.year();
  const isYearValid = year && /^\d{4}$/.test(year.toString());
  if (!isYearValid) {
    control.reset();
  }
  if (!date.isValid() && control.dirty) {
    if (control.touched) control.reset();
    return { dateValidator: true };
  }
  return null;
}

export function futureDateValidator(
  control: AbstractControl
): ValidationErrors {
  const date = moment(control.value);
  if (date > moment()) {
    if (control.touched) control.reset();
    return { 'future-date': date.toString() };
  }

  return null;
}

export function futureDateValidatorAsync(
  control: AbstractControl
): ValidationErrors {
  const date = new Date(Date.parse(control.value));
  return of(!(date > new Date()));
}

export function validateTriage(c: AbstractControl) {
  //console.log('validation', c.value);
  return null;
}

export function ValidateDate(control: AbstractControl) {
  //console.log('abc', control.value);
  if (!control.value.startsWith('https') || !control.value.includes('.io')) {
    return { invalidUrl: true };
  }
  return null;
}

@Injectable({
  providedIn: 'root',
})
export class PhoneSystemIdValidationService {
  constructor(private service: CaseCoreApiService) {}

  phoneSystemIdValidationMessage(error: any, field: FormlyFieldConfig) {
    return 'This Phone System ID already exists';
  }
  phoneSystemIdLengthValidationMessage(error: any, field: FormlyFieldConfig) {
    return 'The Phone System ID must be at least 12 characters.';
  }

  phoneSystemIdValidator = (
    control: AbstractControl
  ): ValidationErrors | null => {
    if (control.value === undefined || control.value === null) {
      return null;
    }
    let selectedFollowUpNumber = control.root['controls'].followup['controls'].calldetails['controls'].followupNumber.value;

     // validate length of field before calling the service
    if (control.value.length < 12) {
      const msg = this.phoneSystemIdLengthValidationMessage(null, null);
      control.setErrors({ 'phonesystemid-length-validation': msg });
      return { 'phonesystemid-length-validation': msg };
    }

    this.service
      .checkduplicatePhoneSystem(control.value)
      .subscribe((result) => {
        if (result) {
          const filteredCalls = result['calls'].filter(call => call.props?.followupNumber !== selectedFollowUpNumber);
          if (filteredCalls.length > 0) {
            var msg = 'Phone System ID ' + control.value + ' already exists';
            control.setErrors({ 'phonesystemid-validation': true });
            return { 'phonesystemid-validation': msg };
          } else {
            return null;
          }
        }
      });
  };
}

@Injectable({
  providedIn: 'root',
})
export class CustomValidationService {
  static parentalConsentValidationMessage:
    | string
    | ((
        error: any,
        field: FormlyFieldConfig<
          FormlyFieldProps & { [additionalProperties: string]: any }
        >
      ) => string | Observable<string>);
  static dateOfDeathValidationMessage:
    | string
    | ((
        error: any,
        field: FormlyFieldConfig<
          FormlyFieldProps & { [additionalProperties: string]: any }
        >
      ) => string | Observable<string>);
  static dateOfInjuryReportValidationMessage:
    | string
    | ((
        error: any,
        field: FormlyFieldConfig<
          FormlyFieldProps & { [additionalProperties: string]: any }
        >
      ) => string | Observable<string>);
  static dateOfInjuryReportValidation: FieldValidatorFn;
  constructor(private dialog: MatDialog) {}

  parentalConsentValidationMessage = (error: any, field: FormlyFieldConfig) => {
    //alert(error);
    return error;
  };
  AssociateDOBValidation = (control: AbstractControl): ValidationErrors => {
    return this.isParentalConsentRequired(control, true)
  };
  EmployeeDOBValidation = (control: AbstractControl): ValidationErrors => {
    return this.isParentalConsentRequired(control, false)
  };
  isParentalConsentRequired = (control: AbstractControl, isAssociate: boolean = false): ValidationErrors => {
    const dob = moment(control.value);
    if (!(control.value && dob.isValid())) return null;

    const rootModel = control.root.value;
    if (!rootModel.eligibility?.triage?.isEmployeeUnder18) return null;

    const isUnder18 = rootModel.eligibility.triage.isEmployeeUnder18;
    const ageInYears = moment().diff(dob, 'years', false);
    const message = isAssociate ? 'Associate' : 'Employee';

    if (ageInYears >= 18) {
        return this.handleAdultValidation(control, ageInYears, isUnder18, message);
    } else {
        return this.handleMinorValidation(control, ageInYears, isUnder18, message);
    }
  };

  private handleAdultValidation = (control: AbstractControl, ageInYears: number, isUnder18: string, message: string): ValidationErrors => {
      if (ageInYears >= 100 && isUnder18 === 'false') {
          const msg = `The ${message.toLowerCase()}'s age is ${ageInYears} years. We are not accepting the DOB where age is greater than 100 years.`;
          this.validationPopup(msg);
          if (control.touched) control.reset();
      }
      if (isUnder18 === 'false') return null;
      const msg = `${message} overage, Is ${message} under 18 answered as Yes, please resolve.`;
      this.validationPopup(msg);
      if (control.touched) control.reset();
      return { 'parental-consent': msg };
  };

  private handleMinorValidation = (control: AbstractControl, ageInYears: number, isUnder18: string, message: string): ValidationErrors => {
      if (ageInYears < 15 && isUnder18 === 'true') {
          const msg = `The ${message.toLowerCase()}'s age is ${ageInYears} years. We are not accepting the DOB where age is less than 15 years.`;
          this.validationPopup(msg);
      }

      if (isUnder18 === 'true') return null;

      const msg = `${message} underage, Is ${message} under 18 answered as No, please resolve.`;
      this.validationPopup(msg);
      if (control.touched) control.reset();
      return { 'parental-consent': msg };
  };

  validationPopup(message: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';

    const data = {
      title: 'Error',
      message: message,
      acceptButtonText: 'Ok',
    };

    const dialogRef = this.dialog.open(ConfirmDialogControlComponent, {
      data,
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe()
      .subscribe((result) => {
        //console.log('modal dialog closed');
      });
  }

  dateOfDeathValidationMessage = (error: any, field: FormlyFieldConfig) => {
    return error;
  };
  AssociateDODValidation = (control: AbstractControl): ValidationErrors => {
    return this.dateOfDeathValidation(control, true)
  };
  EmployeeDODValidation = (control: AbstractControl): ValidationErrors => {
    return this.dateOfDeathValidation(control, false)
  };
  dateOfDeathValidation = (control: AbstractControl, isAssociateDOD: boolean = false): ValidationErrors => {
    if (control.value == undefined || control.value == null) {
      return null;
    }
    const type = isAssociateDOD ? 'Associate' : 'Employee';
    const message =  `${type} Date of death cannot be prior to date of injury.`;
    const dateOfDeath = new Date(Date.parse(control.value));
    if (dateOfDeath > new Date()) {
      control.root.get('eligibility.triage.dateOfDeath').setValue(null);
      return {
        'dateofdeath-validation': message,
      };
    }
    let doi = control.root.get(
      'intake.supervisor.accidentDescription.dateTimeOfInjury'
    )?.value;

    if (doi != undefined && doi != '') {
      let doiM = moment(doi).format('YYYY-MM-DD');
      let dateOfDeathM = moment(control.value).format('YYYY-MM-DD');
      if (dateOfDeathM < doiM) {
        control.root.get('eligibility.triage.dateOfDeath').setValue(null);
        this.validationPopup(
          message
        );
        return {
          'dateofdeath-validation': message,
        };
      }
    }
    return null;
  };

  dateOfInjuryReportValidationMessage = (
    error: any,
    field: FormlyFieldConfig
  ) => {
    return error;
  };

  dateOfInjuryReportValidation = (
    control: AbstractControl
  ): ValidationErrors => {
    if (control.value == undefined || control.value == null) {
      return null;
    }

    let reportTime = control.root.get(
      'intake.supervisor.accidentDescription.dateReported'
    )?.value;

    let doiTZone = control.root.get(
      'intake.supervisor.accidentDescription.injuryTimeZone'
    )?.value;

    let doInj = control.root.get(
      'intake.supervisor.accidentDescription.dateTimeOfInjury'
    )?.value;

    var lagTimeDiffSec = this.getDoiReportDiff(doInj, reportTime, doiTZone);
    const defaultValue = (control as any).defaultValue;

    if (lagTimeDiffSec < 0) {

      if(doInj > defaultValue ){
        this.validationPopup(
          'Date of Injury cannot be in the future.'
        );
        if (control.touched) control.reset();
      }      
    }
    return null;
  };

  getDoiReportDiff(doi, reportTime, doiTZone) {
    let doiTz = 'America/New_York';
    switch (doiTZone) {
      case 'cst':
        doiTz = 'America/Chicago';
        break;
      case 'est':
        doiTz = 'America/New_York';
        break;
      case 'mst':
        doiTz = 'America/Denver';
        break;
      case 'pst':
        doiTz = 'America/Los_Angeles';
        break;
      case 'ast':
        doiTz = 'US/Alaska';
        break;
      case 'hst':
        doiTz = 'US/Hawaii';
        break;
    }
    // Parse the input dates and apply the separate timezone offset to 'doi'
    const doiDate = moment.tz(doi, doiTz);
    const utcDateParsed = moment.utc(reportTime);
    const reportDateinTz = moment.tz(utcDateParsed, doiTz);
    // Calculate the difference in Seconds
    let differenceInSec = Math.floor(reportDateinTz.diff(doiDate, 'seconds'));
    return differenceInSec;
  }

  dateOfManagerNotifiedValidationMessage = (
    error: any,
    field: FormlyFieldConfig
  ) => {
    return error;
  };

  dateOfManagerNotifiedValidation = (
    control: AbstractControl
  ): ValidationErrors => {
    if (
      control.value == undefined ||
      control.value == null ||
      control.value == ''
    ) {
      return null;
    }
    let doi = control.root.get(
      'intake.supervisor.accidentDescription.dateTimeOfInjury'
    )?.value;

    if (doi != undefined && doi != '') {
      let doiM = moment(doi).format('YYYY-MM-DD');
      let dateManagerNotifiedM = moment(control.value).format('YYYY-MM-DD');
      if (dateManagerNotifiedM < doiM) {
        control.root
          .get('intake.supervisor.demographics.dateManagerNotified')
          .setValue(null);
        this.validationPopup('Cannot be less than date of injury.');
        return {
          'dateofmanagernotified-validation':
            'Cannot be less than date of injury.',
        };
      }
    }
    return null;
  };
}
