
import {
  getSelectedAccountDetailView,
  selectAccountEntities,
} from '@accounting/data';
import { CallAction, CaseOrigin, CaseTypeDescriptions } from '@nfr/common';
import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import {
  getFirstCall,
  getFlatCaseModel,
  isCaseLoaded,
  selectAssembly,
  selectCaseMetaData,
  selectCaseState,
} from './case.selectors';
import { getAnotherPerson, getEmployee, getSupervisor } from './model.selectors';

export const getCaseProgress = createSelector(
  selectCaseState,
  selectAssembly,
  getFlatCaseModel,
  isCaseLoaded,
  (state, assembly, fields, loaded) => {
    if (!loaded) return [];

    const results = [];
    _.forEach(assembly.forms, (form) => {
      const formKey = form.key;
      const raw = fields.filter(
        (f) => f.baseKey?.toLowerCase().indexOf(formKey?.toLowerCase()) != -1
      );
      const rawWithArea = raw.map((field) => {
        const area = form.template?.areas.find(
          (a) => a.id === field.field.areaId
        );
        return { ...field, area };
      });
      const progress = mapProgress(rawWithArea, state);
      const final = {
        key: formKey,
        form,
        progress,
      };
      results.push(final);
    });

    return results;
  }
);

export const getInfo2 = createSelector(
  selectCaseState,
  selectAccountEntities,
  (state, accounts) => {
    const data = [];
    if (!state.loaded) return [];
    const employee = state.calls[0].props.employee;
    const supervisor = state.calls[0].props.supervisor;
    const account = accounts[state.calls[0].accountId];

    data.push(
      { key: 'Employee', value: employee.firstName + ' ' + employee.lastName },
      { key: 'Status', value: state.status, props: { icon: 'check_circle' } },
      {
        key: 'Supervisor',
        value: supervisor.firstName + ' ' + supervisor.lastName,
      },
      {
        key: 'Reference',
        value: state.referenceNumber,
        props: { clipboardCopy: true },
      },
      { key: 'Call Start Time', value: state.calls[0].startTime },
      { key: 'Account', value: account ? account.name : '' }
    );

    return data;
  }
);
export const getInfo = createSelector(
  selectCaseState,
  getEmployee,
  getSupervisor,
  getFirstCall,
  selectCaseMetaData,
  getSelectedAccountDetailView,
  (state, employee, supervisor, call, caseMetaData, account) => {
    const data = [];
    if (!state.loaded) return [];


    let initialCaller;
    let initialCallerIntake;
    let completedBy;
    let createdBy; 

    let callStartTime = state?.model?.triageDate;
    if(caseMetaData.caseOrigin == CaseOrigin.LRMTransfer){
      initialCallerIntake = true;
      if(caseMetaData?.completedBy && state.status == 'Completed'){
        completedBy = caseMetaData?.completedBy?.lastName + ', ' + caseMetaData?.completedBy?.firstName
      }
      else if(!state?.model?.updatedBy && caseMetaData.createdBy?.lastName == caseMetaData.createdBy?.firstName){
        createdBy = caseMetaData.createdBy?.lastName;        
      }else{
        createdBy = caseMetaData.createdBy?.lastName + ', ' + caseMetaData.createdBy?.firstName;
      }     
    }
    else{
      createdBy = caseMetaData.createdBy?.lastName + ', ' + caseMetaData.createdBy?.firstName;
    }
    
    const useAssociateManagerAsLabel = account?.props?.isLabelChangeEnabled;
    initialCaller= getInitialCaller(call,useAssociateManagerAsLabel);

    const translation = state?.model?.translation;
    let language = translation?.language;
    let translatorName = translation?.translatorName;
    if (!translation?.language) {
      language = 'English';
    } else if (translation?.language?.toLowerCase() === 'other') {
      language = translation?.other;
      if (!language) {
        language = 'N/A';
      }
    }

    if (!translatorName) {
      translatorName = 'N/A';
    }
    const empCallName =
      call?.props?.employee?.firstName + ' ' + call?.props?.employee?.lastName;
    const employeeName =
      employee.fullName !== '' ? employee.fullName : empCallName;

    const calls = state.calls
    const newCaseCall = calls.find(call => call.action === CallAction[CallAction.NewCase]);

    const startTime = newCaseCall ? newCaseCall.startTime : null;

    const estFormattedDate = moment(startTime)
      .tz('America/New_York')
      .format('MM/DD/yyy HH:mm:ss');
      
    const estFormattedStartDate = callStartTime ? moment(callStartTime)
    .tz('America/New_York')
    .format('MM/DD/yyy hh:mm A') : null;  
   
    data.push(
      { key: useAssociateManagerAsLabel ? 'Associate' : 'Employee' , value: employeeName },
      { key: useAssociateManagerAsLabel ? 'Manager' : supervisor.nameLabel, value: supervisor.fullName },
      { key: 'Account', value: account ? account.name : '' },
      { key: 'Call Back Number', value: account ? account.phoneNumber : '' },
      {
        key: 'Case Reference ID',
        value: state.referenceNumber.toUpperCase(),
        props: { clipboardCopy: true },
      },
      { key: 'Call Start Time (EST)', value: initialCallerIntake? estFormattedStartDate : estFormattedDate },
      { key: 'Status', value: state.status, props: { icon: 'check_circle' } },
      { key: 'Case Type', value: CaseTypeDescriptions[caseMetaData.caseType] },

      { key: 'Language', value: language },
      { key: 'Translator Name', value: translatorName },
      { key: 'Created by', value: completedBy? completedBy : createdBy },
      { key: initialCallerIntake ? 'Transferred by': 'Initial Caller', value: initialCallerIntake ? caseMetaData.transferredBy : initialCaller }
    );

    return data;
  }
);
export const getIsDocumentForProviderEnabled = createSelector(selectCaseState, selectAccountEntities,(state,accounts)=>{
  const account = accounts[state.calls[0].accountId];
  return account?.props?.isDocumentForProviderEnabled;
});
export const getTranslation = createSelector(
  selectCaseState,
  (state) => {
    const data = [];
    if (!state.loaded) return [];
    const translation = state?.model?.translation;
    data.push(
      { key: 'translatorID', value: translation.translatorID },
      { key: 'translatorName', value: translation.translatorName }
    );
    return data;
  }
);
export function mapProgress(fields: any[], state: any) {
  const fieldToSkip = ['formly-label'];
  const nonLabelFields = fields.filter(
    (f: any) => !fieldToSkip.includes(f.field.control)
  );
  const visibleFields = nonLabelFields.filter((f: any) => {
    let isFieldVisible = true;
    if (
      f.area?.props.hideExpression &&
      f.area?.props.hideExpression.length > 0
    ) {
      let exp = 'state?.' + f.area.props.hideExpression.replace(/(?<!\?)\.(?!\.)/g, '?.');

      try {
        if (eval(exp.split(' ')[0]) == undefined || eval(exp)) {
          isFieldVisible = false;
        }
      } catch (ex) {
        //expression is invalid so field is not hidden
      }
    }
    if (f.field.hideExpression && f.field.hideExpression.length > 0) {
      let exp = 'state?.' + f.field.hideExpression.trimStart().replace(/(?<!\?)\.(?!\.)/g, '?.');

      try {
        if (eval(exp.split(' ')[0]) == undefined || eval(exp)) {
          isFieldVisible = false;
        }
      } catch (ex) {
        //expression is invalid so field is not hidden
      }
    }
    return isFieldVisible;
  });

  let total = visibleFields.length;
 const answeredFields = visibleFields.filter((f: any) => {
    if (f?.field?.props?.defaultValue != undefined && f?.field?.props?.defaultValue != '') {
      let exp = 'state?.model?.' + f.key.replace(/(?<!\?)\.(?!\.)/g, '?.');;
      if (eval(exp) == undefined || eval(exp) != '') {
        return true;
      }
    }
    return f.value !== ''
  });

  const answer = answeredFields.length;

  const progress = (answer / total) * 100;
  const ruleStatus = triageEligibilityRuleStatus(fields, state);
  return {
    fields,
    total,
    answer,
    progress,
    ruleStatus,
    visibleFields,
  };
}

export function triageEligibilityRuleStatus(formFields: any, state: any) {
  let isRulePassed = false;
  if (formFields) {
    if (formFields.length > 0) {
      let baseKey = formFields[0]?.baseKey;
      if (baseKey == 'eligibility') {
        if (
          formFields.find(
            (q) => q.key == 'eligibility.triage.injuryResultInDeath'
          ).value === 'false' &&
          formFields.find((q) => q.key == 'eligibility.triage.isReportOnly')
            .value === 'false' &&
          formFields.find(
            (q) => q.key == 'eligibility.triage.employeeSoughtMedicalCare'
          ).value === 'false' &&
          formFields.find(
            (q) => q.key == 'eligibility.triage.isEmployeeAvailable'
          ).value === 'true' &&
          formFields.find((q) => q.key == 'eligibility.triage.parentalConsent')
            .value === 'true'
        ) {
          isRulePassed = true;
        }
      }
    }
  }
  return isRulePassed;
}

export function getInitialCaller(call : any, isLabelchaged:boolean)
{
  let  initialCaller='';
  if (isLabelchaged) {
    initialCaller =
      call.props.employee?.isCaller === 'true' ? 'Associate' : 'Manager';
  } else if(call.props.another?.isCaller=== 'true') {
    initialCaller ='Another';
  }else
  {
    initialCaller =
      call.props.employee?.isCaller === 'true' ? 'Employee' : 'Supervisor';
  }
  return initialCaller
}
