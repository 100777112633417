import { Component, Input } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-custom-input',
  template: `
    <div class="custom-input {{props.cssClassName != '' ? props.cssClassName : 'flexRow'}} {{props.required ? 'required-input-field' : ''}} {{props.disabled ? 'disabled-input-field' : ''}}  {{formControl.invalid ? '' : 'valid-input-field'}}"   >
      <mat-label [matTooltip]="props.toolTip ?? props.description" matTooltipPosition="after">{{props.label}}</mat-label>
     <mat-icon *ngIf="props.tooltip" [matTooltip]="props.tooltip.text">{{props.tooltip.icon}}</mat-icon>
      <input matInput [formControl]="formControl"  [required]="props.required" [formlyAttributes]="field" [type]="props.type">
      <div *ngIf="isInvalid" class="error-messages">
          <div *ngFor="let msg of getMessages">{{msg}}</div>
      </div>
    </div>
 `,
  styles: [
    `
    .custom-input{
      ::ng-deep{
        .mat-input-element{
          box-sizing: border-box;
        }
      }
    }

    .custom-input mat-icon{
      height: 16px;
      width: 16px;
      font-size: 14px;
      line-height: unset;
      margin-left: 6px;
      margin-bottom: -3px;
    }
      .error-messages div{
        padding: 0;
        color: #FF0000;
        font-size: 10px;
        margin-top: 4px;
      }
      .error-messages{
        position: absolute;
      }
    `,  
  ],
})
export class CustomInputFieldType extends FieldType<FieldTypeConfig> {
  constructor() {
    super();
  }

  get isInvalid() {
    return this.formControl.invalid && this.formControl.touched;
  }

  get getMessages(): string[] {   
    const errorMessages: string[] = [];  
    const errors = this.formControl.errors;  
    if (!errors) {     
        return errorMessages; // No errors, return an emptyarray  
    }
    if(errors["phonesystemid-validation"]){
      errorMessages.push('Phone System ID ' + this.formControl.value + ' already exists');
    }
    if(errors["phonesystemid-length-validation"]){
      errorMessages.push(errors["phonesystemid-length-validation"]);
    }
    if (errors.textValidation?.message) {     
        errorMessages.push(errors.textValidation.message);
    } 
    else if (errors.minLength?.requiredLength) {
        errorMessages.push(`Should have at least ${errors.minLength.requiredLength} characters`); 
    } 
    return errorMessages; 
  }
}
