import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { Observable, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { LookupService } from '../../services/lookup.service';

import { MatInput } from '@angular/material/input';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AutocompleteSelectionDirective } from '@autobot/shared';

@Component({
  selector: 'formly-autocompletehint-type',
  template: `
    <div
      class="custom-select-wrapper"
      [ngClass]="{
        'required-field': props.required,
        'disabled-field': formControl.disabled,
        'valid-field': formControl.valid
      }"
    >
      <mat-label>
        {{ to.label }}
      </mat-label>
      <mat-form-field appearance="fill" class="dropdown-field">
        <input
          matInput
          autobotForceSelection
          [matAutocomplete]="auto"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [placeholder]="stateOfJurisdictionInstructions"
          [errorStateMatcher]="errorStateMatcher"
          [name]="props.dataHint"
          autocomplete="no"
        />

        <a
          *ngIf="value && !disabled"
          matSuffix
          aria-label="Clear"
          (click)="clear()"
        >
          <mat-icon class="closeIcon">close</mat-icon>
        </a>

        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
          <mat-option *ngIf="(filter | async)?.length === 0" disabled>
            No items
          </mat-option>
          <mat-option
            *ngFor="let opt of filter | async"
            [value]="opt.label"
            (onSelectionChange)="onSelectionChange(opt, $event)"
            [title]="opt.label"
          >
            {{ opt.label }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      .mat-input-element {
        width: 90%;
      }
      .error {
        font-size: 75%;
        position: absolute;
        margin-top: 8px;
      }
      .closeIcon {
        font-size: 12px;
        height: 12px;
      }
    `,
  ],
})
export class FormlyLookuphintAutocompleteComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, AfterViewInit
{
  @ViewChild(AutocompleteSelectionDirective, { static: true })
  directive: AutocompleteSelectionDirective;
  @ViewChild(MatInput) formFieldControl: MatInput;

  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  lookupSvc = inject(LookupService);

  filter!: Observable<any>;

  isLoading = false;
  errorMsg!: string;
  minLengthTerm = 3;
  stateOfJurisdictionInstructions: string;
  ngAfterViewInit(): void {
    (<any>this.autocomplete)._formField = this.formField;
  }
  ngOnInit() {
    this.filter = this.formControl.valueChanges.pipe(
      startWith(''),
      switchMap((term) => {
        const result = this.lookupSvc.filter(term, this.props.dataHint);
        return result;
      })
    );

    this.getAccountInsructions();
  }

  getAccountInsructions() {
    const accountId = this.lookupSvc.getAccountId();
    this.lookupSvc
      .getAccountConfigByAccountIdKeyName('SOJ_Instructions', accountId)
      .subscribe((data) => {
        if (data) {
          this.stateOfJurisdictionInstructions = data[0].instruction;
        }
      });
  }

  clear() {
    this.formControl.setValue('');
  }

  onSelectionChange(event, evt: any) {
    this.formControl.enable();
  }
}
