import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromCase from '../reducers/case.reducer';

import {
  CallForm,
  CaseAssignment,
  CaseMetaData,
  CommonFunctionService,
  CreateCallDTO,
  CreateCaseSerchDTO,
  Note,
  Protocols,
  RightSideTabs,
  SelectedDisposition,
  TelemedicineConfigs,
} from '@nfr/common';
import {
  activateEmergencyCase,
  addNote,
  addProtocols,
  applyCaseRules,
  clearTriageData,
  completeCase,
  createCase,
  deActivateEmergencyCase,
  evaluateCaseType,
  handle911EmergencyClick,
  loadCase,
  loadSearchCases,
  removeProtocols,
  removeProtocolsSuccess,
  saveCase,
  saveCaseAndNavigate,
  searchCases,
  updateCaseModel,
  updateCompleting,
  updateSecondaryGaugesData,
  updateStandardProtocolAnswer,
  updateValidCaseFormList,
  updateCaseMetaDataOnCaseAssign
} from '../actions/case.actions';
import {
  getCaseStatus,
  getEmergencyButtonAttribute,
  getEmergencyButtonDisabledStatus,
  getIsEmergencyCase,
  getNotes,
  getProtocols,
  getSelectedRightSideTab,
  isCaseLoaded,
  selectAssembly,
  selectCaseFormKeys,
  selectCaseMetaData,
  selectCaseState,
  selectCaseValidFormList,
  selectCurrentState,
  selectModel,
  selectSaved,
  selectSaving,
  selectSelectedDisposition,
  selectTelemedicineConfig,
  selectIsTelemedicineDispositionAvailable,
} from '../selectors/case.selectors';

import { NgrxRoute } from '@autobot/core';
import { Assembly, ICaseRule } from '@forms/common';
import { createCall } from '../actions/call.actions';
import { saveNoteAPI } from '../actions/case-api.actions';
import { sessionStarted } from '../actions/session.actions';
import { getCaseProgress } from '../selectors';
import { validateTelemedicine } from '../actions/case.actions';
@Injectable({ providedIn: 'root' })
export class CaseFacade {
  constructor(private store: Store<fromCase.State>, private commonFunction:CommonFunctionService) {}

  loaded$: Observable<boolean> = this.store.select(isCaseLoaded);
  saved$: Observable<boolean> = this.store.select(selectSaved);
  saving$: Observable<boolean> = this.store.select(selectSaving);
  progress$: Observable<any> = this.store.select(getCaseProgress);
  isTelemedicineDispositionAvailable$:  Observable<boolean> = this.store.select(selectIsTelemedicineDispositionAvailable);
  model$: Observable<any> = this.store.select(selectModel);
  case$: Observable<any> = this.store.select(selectCaseState);
  caseStatus$: Observable<string> = this.store.select(getCaseStatus);
  caseMetaData$: Observable<CaseMetaData> =
    this.store.select(selectCaseMetaData);
  selectedDisposition$: Observable<SelectedDisposition> = this.store.select(selectSelectedDisposition);
 
  formKeys$: Observable<string[]> = this.store.select(selectCaseFormKeys);

  notes$: Observable<Note[]> = this.store.select(getNotes);
  protocols$: Observable<any[]> = this.store.select(getProtocols);
  assembly$: Observable<Assembly> = this.store.select(selectAssembly);
  isEmergencyCase$: Observable<boolean> = this.store.select(getIsEmergencyCase);
  telemedicineConfig$:Observable<TelemedicineConfigs> = this.store.select(selectTelemedicineConfig);
  selectedRightSideTab$: Observable<RightSideTabs> = this.store.select(
    getSelectedRightSideTab
  );
  isEmergencyButonDisabled$: Observable<boolean> = this.store.select(
    getEmergencyButtonDisabledStatus
  );

  emergencyButonAttribute$: Observable<any> = this.store.select(
    getEmergencyButtonAttribute
  );
  currentStateModel$: Observable<any> = this.store.select(selectCurrentState);
  caseValidFormList$: Observable<any> = this.store.select(selectCaseValidFormList);
  createNewCase(call: CallForm, account: any, agent: string, user:any) {
    this.store.dispatch(createCase({ call, account, agent,user }));
  }
  createCall(call: CreateCallDTO) {
    this.store.dispatch(createCall({ call }));
  }

  loadSearchCases(call: CreateCaseSerchDTO): void {
    this.store.dispatch(loadSearchCases({ call }));
  }
  updateCaseModel(model: any) {
    this.store.dispatch(updateCaseModel({ data: model }));
  }

  updateMergedCaseModel(caseModel: any, currentFormModel: any) {
    let _mergedModel = this.commonFunction.deepMergeObject(caseModel, currentFormModel);
    if (currentFormModel?.eligibility?.triage?.injuryResultInDeath) {
      if (
        currentFormModel?.eligibility?.triage?.injuryResultInDeath != 'true'
      ) {
        _mergedModel.eligibility.triage['dateOfDeath'] = null;
      }
      if (currentFormModel?.eligibility?.triage?.isEmployeeUnder18 != 'true') {
        _mergedModel.eligibility.triage['parentalConsent'] = null;
      }
    }
    this.updateCaseModel(_mergedModel);
  }

  completeCase(completedBy: any) {
    this.store.dispatch(completeCase({ completedBy }));
  }
  addNote(note: Note) {
    this.store.dispatch(addNote({ note }));
  }

  addProtocols(protocols: Protocols,protocolspecialInstruction:boolean) {
    this.store.dispatch(addProtocols({ protocols ,protocolspecialInstruction}));
  }
  removeProtocols(protocols: Protocols) {
    this.store.dispatch(removeProtocols({ protocols }));
  }
  removeProtocolsSuccess(protocols: Protocols,protocolspecialInstruction:boolean) {
    this.store.dispatch(removeProtocolsSuccess({ protocols ,protocolspecialInstruction}));
  }
  /**
   * @description Primary action for loading the case
   */
  loadCase(id: string): void {
    this.store.dispatch(loadCase({ id }));
  }

  saveCase(): void {
    this.store.dispatch(saveCase());
  }
  saveCaseAndGo(to: NgrxRoute): void {
    this.store.dispatch(saveCaseAndNavigate({ to }));
  }
  saveNoteAPI(): void {
    this.store.dispatch(saveNoteAPI());
  }
  startNewSession(): void {
    this.store.dispatch(sessionStarted());
  }

  searchCases(searchText: string, tenant: string) {
    this.store.dispatch(searchCases({ searchText, tenant }));
  }

  handle911EmergencyClick() {
    this.store.dispatch(handle911EmergencyClick());
  }

  activate911Emergency() {
    this.store.dispatch(activateEmergencyCase());
  }
  validateTelemedicine(stateOfJurisdiction: string){
    const data = {
      stateOfJurisdiction
    };
    this.store.dispatch(validateTelemedicine({ data }));
  }

  deActivate911Emergency() {
    this.store.dispatch(deActivateEmergencyCase());
  }
  applyCaseBasedRule(caseRule: ICaseRule) {
    return this.store.dispatch(applyCaseRules({ rules: caseRule }));
  }
  updateStandardProtocolAnswer(data: any) {
    this.store.dispatch(updateStandardProtocolAnswer({ data }));
  }
  evaluateCaseType(data: any) {
    this.store.dispatch(evaluateCaseType({ data }));
  }
  updateCompleting() {
    this.store.dispatch(updateCompleting());
  }
  updateValidCaseFormList(model: any) {
    this.store.dispatch(updateValidCaseFormList({ data: model }));
  }
  clearTriageData() {
    this.store.dispatch(clearTriageData());
  }
  updateSecondaryGaugeData(model: any) {
    this.store.dispatch(updateSecondaryGaugesData({ data: model }));
  }
  updateCaseMetaDataFromSearch(user: any) {
    this.store.dispatch(updateCaseMetaDataOnCaseAssign( { user: user} ));
  }
}
